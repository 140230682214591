<template>
  <div class="page">
    <div style="width: 21.6cm;height: 27.9cm; page-break-after:always;">
      <img src="https://website.cobalt47.cn/spec/dlaqtable.jpeg" style="width: 98%;">
    </div>
    <div class="row" style="text-align: left;padding:100px 45px 0 45px;">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left;font-size: 24px;">
            附14
          </div>
          <div style="width: 89%; float: right">
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b>原料安全相关信息</b>
            </p>
          </div>
        </div>
        <div style="clear: both"></div>
        <div style="height: 20px"></div> 
        <div style="font-size:18px;text-align: center;"><b>【原料商品名、原料基本信息、原料生产工艺简述】</b></div>
        <table
        style="width: 98%;text-align: center;"
        border="1px gray"
        cellspacing="0"
        class="tab"
        >
        <tr>
            <td style="width:20%;"><span>原料商品名</span></td>
            <td style="width:80%;" colspan="4"><span style="text-align: left;">{{printModel.formModel.productName}}</span></td>
        </tr>
        <tr>
            <td style="width:20%;" :rowspan="rowspanCount"><span>原料组成（包括复配组分）</span></td>
            <td style="width:5%;"><span>组分</span></td>
            <td style="width:25%;"><span>组分中文名称</span></td>
            <td style="width:25%;"><span>组分INCI名称</span></td>
            <td style="width:25%;"><span>百分比范围</span></td>
        </tr>
        <tr :key="i+1000" v-for="(tag, i) in maxArr">
            <td><span>{{i+1}}</span></td>
            <td v-show="printModel.commonOtherNameArr.length > 0">
                <div v-show="i + 1 <= printModel.commonOtherNameArr.length">{{
                printModel.commonOtherNameArr[i]
                }}</div>
            </td>
            <td v-show="printModel.inclNameArr.length > 0">
                  <div v-show="i + 1 <= printModel.inclNameArr.length">{{
                    printModel.inclNameArr[i]
                  }}</div>
                </td>
            <td v-show="printModel.percentageArr.length > 0">
                <div v-show="i + 1 <= printModel.percentageArr.length">{{
                printModel.percentageArr[i]
                }}</div>
            </td>
        </tr>
        <tr v-show="printModel.formModel.customRowKey1">
            <td><span>{{maxArrLength+1}}</span></td>
            <td><span>{{printModel.formModel.customRowKey1}}</span></td>
            <td><span>{{printModel.formModel.customRowValue1_1}}</span></td>
            <td><span>{{printModel.formModel.customRowValue1_2}}</span></td>
        </tr>
        <tr v-for="(item, index1) in printModel.customRowArr1" :key="index1+2000">
            <td><span>{{maxArrLength+index1+customRowKey1+1}}</span></td>
            <td><span>{{item.customRowKey1}}</span></td>
            <td><span>{{item.customRowValue1}}</span></td>
            <td><span>{{item.customRowValue2}}</span></td>
        </tr>
        <tr v-show="printModel.formModel.isGlycerin">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length+1}}</span></td>
            <td><span>甘油</span></td>
            <td><span>{{printModel.formModel.glycerin1}}</span></td>
            <td><span>{{printModel.formModel.glycerin2}}</span></td>
        </tr>
        <tr v-show="printModel.formModel.isPropyleneGlycol">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length+glycerinNumber+1}}</span></td>
            <td><span>丙二醇</span></td>
            <td><span>{{printModel.formModel.propyleneGlycol1}}</span></td>
            <td><span>{{printModel.formModel.propyleneGlycol2}}</span></td>
        </tr>
        <tr v-show="printModel.formModel.isPolysorbate_80">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length+glycerinNumber+propyleneGlycolNumber+1}}</span></td>
            <td><span>聚山梨醇酯-80</span></td>
            <td><span>{{printModel.formModel.polysorbate_80_1}}</span></td>
            <td><span>{{printModel.formModel.polysorbate_80_2}}</span></td>
        </tr>
        <tr v-show="printModel.formModel.isCaprylhydroxamicAcid">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length
              +glycerinNumber+propyleneGlycolNumber+polysorbate_80Number
              +1}}</span></td>
            <td><span>辛酰羟肟酸</span></td>
            <td><span>{{printModel.formModel.caprylhydroxamicAcid1}}</span></td>
            <td><span>{{printModel.formModel.caprylhydroxamicAcid2}}</span></td>
        </tr>
        <tr v-show="printModel.formModel.isOneTwoHexanediol">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length
              +glycerinNumber+propyleneGlycolNumber+polysorbate_80Number+caprylhydroxamicAcidNumber
              +1}}</span></td>
            <td><span>1,2-己二醇</span></td>
            <td><span>{{printModel.formModel.oneTwoHexanediol1}}</span></td>
            <td><span>{{printModel.formModel.oneTwoHexanediol2}}</span></td>
        </tr>
        <tr v-show="printModel.formModel.isOneThreePropanediol">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length
              +glycerinNumber+propyleneGlycolNumber+polysorbate_80Number+caprylhydroxamicAcidNumber+oneTwoHexanediolNumber
              +1}}</span></td>
            <td><span>1,3-丙二醇</span></td>
            <td><span>{{printModel.formModel.oneThreePropanediol1}}</span></td>
            <td><span>{{printModel.formModel.oneThreePropanediol2}}</span></td>
        </tr>
        <tr v-show="printModel.formModel.customRowKey2">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length
              +glycerinNumber+propyleneGlycolNumber+polysorbate_80Number+caprylhydroxamicAcidNumber+oneTwoHexanediolNumber+oneThreePropanediolNumber
              +1}}</span></td>
            <td><span>{{printModel.formModel.customRowKey2}}</span></td>
            <td><span>{{printModel.formModel.customRowValue2_1}}</span></td>
            <td><span>{{printModel.formModel.customRowValue2_2}}</span></td>
        </tr>
        <tr v-for="(item, index2) in printModel.customRowArr2" :key="index2+3000">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length+customRowKey2
              +glycerinNumber+propyleneGlycolNumber+polysorbate_80Number+caprylhydroxamicAcidNumber+oneTwoHexanediolNumber+oneThreePropanediolNumber
              +index2
              +1}}</span></td>
            <td><span>{{item.customRowKey1}}</span></td>
            <td><span>{{item.customRowValue1}}</span></td>
            <td><span>{{item.customRowValue2}}</span></td>
        </tr>
        <tr v-show="printModel.formModel.isPhenoxyethanol">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length+customRowKey2+customRowArr2Length
              +glycerinNumber+propyleneGlycolNumber+polysorbate_80Number+caprylhydroxamicAcidNumber+oneTwoHexanediolNumber+oneThreePropanediolNumber
              +1}}</span></td>
            <td><span>苯氧乙醇</span></td>
            <td><span>{{printModel.formModel.phenoxyethanol1}}</span></td>
            <td><span>{{printModel.formModel.phenoxyethanol2}}</span></td>
        </tr>
        <tr v-show="printModel.formModel.isDmdmHydantoin">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length+customRowKey2+customRowArr2Length
              +glycerinNumber+propyleneGlycolNumber+polysorbate_80Number+caprylhydroxamicAcidNumber+oneTwoHexanediolNumber+oneThreePropanediolNumber
              +phenoxyethanolNumber
              +1}}</span></td>
            <td><span>DMDM 乙内酰脲</span></td>
            <td><span>{{printModel.formModel.dmdmHydantoin1}}</span></td>
            <td><span>{{printModel.formModel.dmdmHydantoin2}}</span></td>
        </tr>
        <tr v-show="printModel.formModel.isSodiumBenzoate">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length+customRowKey2+customRowArr2Length
              +glycerinNumber+propyleneGlycolNumber+polysorbate_80Number+caprylhydroxamicAcidNumber+oneTwoHexanediolNumber+oneThreePropanediolNumber
              +phenoxyethanolNumber+dmdmHydantoinNumber
              +1}}</span></td>
            <td><span>苯甲酸钠</span></td>
            <td><span>{{printModel.formModel.sodiumBenzoate1}}</span></td>
            <td><span>{{printModel.formModel.sodiumBenzoate2}}</span></td>
        </tr>
        <tr v-show="printModel.formModel.isPotassiumSorbate">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length+customRowKey2+customRowArr2Length
              +glycerinNumber+propyleneGlycolNumber+polysorbate_80Number+caprylhydroxamicAcidNumber+oneTwoHexanediolNumber+oneThreePropanediolNumber
              +phenoxyethanolNumber+dmdmHydantoinNumber+sodiumBenzoateNumber
              +1}}</span></td>
            <td><span>山梨酸钾</span></td>
            <td><span>{{printModel.formModel.potassiumSorbate1}}</span></td>
            <td><span>{{printModel.formModel.potassiumSorbate2}}</span></td>
        </tr>
        <tr v-show="printModel.formModel.isEdta">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length+customRowKey2+customRowArr2Length
              +glycerinNumber+propyleneGlycolNumber+polysorbate_80Number+caprylhydroxamicAcidNumber+oneTwoHexanediolNumber+oneThreePropanediolNumber
              +phenoxyethanolNumber+dmdmHydantoinNumber+sodiumBenzoateNumber+potassiumSorbateNumber
              +1}}</span></td>
            <td><span>乙二胺四乙酸</span></td>
            <td><span>{{printModel.formModel.edta1}}</span></td>
            <td><span>{{printModel.formModel.edta2}}</span></td>
        </tr>
        <tr v-show="printModel.formModel.isWater">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length+customRowKey2+customRowArr2Length
              +glycerinNumber+propyleneGlycolNumber+polysorbate_80Number+caprylhydroxamicAcidNumber+oneTwoHexanediolNumber+oneThreePropanediolNumber
              +phenoxyethanolNumber+dmdmHydantoinNumber+sodiumBenzoateNumber+potassiumSorbateNumber+edtaNumber
              +1}}</span></td>
            <td><span>水</span></td>
            <td><span>{{printModel.formModel.water1}}</span></td>
            <td><span>{{printModel.formModel.water2}}</span></td>
        </tr>
        <tr v-show="printModel.formModel.customRowKey3">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length+customRowKey2+customRowArr2Length
              +glycerinNumber+propyleneGlycolNumber+polysorbate_80Number+caprylhydroxamicAcidNumber+oneTwoHexanediolNumber+oneThreePropanediolNumber
              +phenoxyethanolNumber+dmdmHydantoinNumber+sodiumBenzoateNumber+potassiumSorbateNumber+edtaNumber+waterNumber
              +1}}</span></td>
            <td><span>{{printModel.formModel.customRowKey3}}</span></td>
            <td><span>{{printModel.formModel.customRowValue3_1}}</span></td>
            <td><span>{{printModel.formModel.customRowValue3_2}}</span></td>
        </tr>
        <tr v-for="(item, index3) in printModel.customRowArr3" :key="index3+4000">
            <td><span>{{maxArrLength+customRowKey1+customRowArr1Length+customRowKey2+customRowArr2Length+customRowKey3
              +glycerinNumber+propyleneGlycolNumber+polysorbate_80Number+caprylhydroxamicAcidNumber+oneTwoHexanediolNumber+oneThreePropanediolNumber
              +phenoxyethanolNumber+dmdmHydantoinNumber+sodiumBenzoateNumber+potassiumSorbateNumber+edtaNumber+waterNumber
              +index3
              +1}}</span></td>
            <td><span>{{item.customRowKey1}}</span></td>
            <td><span>{{item.customRowValue1}}</span></td>
            <td><span>{{item.customRowValue2}}</span></td>
        </tr>
        <tr>
            <td style="width:20%;"><span>化妆品中建议添加量</span></td>
            <td style="width:80%;" colspan="4"><span style="text-align: left;">{{printModel.formModel.recommendedAmountCosmetics}}</span></td>
        </tr>
        <tr>
            <td style="width:20%;"><span>原料使用限制（如有）</span></td>
            <td style="width:80%;" colspan="4"><span style="text-align: left;">{{printModel.formModel.restrictionsUseRawMaterials}}</span></td>
        </tr>
        <tr>
            <td style="width:20%;"><span>原料性状</span></td>
            <td style="width:80%;" colspan="4"><span style="text-align: left;">{{printModel.formModel.propertiesRawMaterials}}</span></td>
        </tr>
        <tr>
            <td style="width:20%;"><span>物理化学性质描述</span></td>
            <td style="width:80%;" colspan="4"><span style="text-align: left;">{{printModel.formModel.descPhysChemProperties}}</span></td>
        </tr>
        <tr>
            <td style="width:20%;"><span>生产工艺类型描述</span></td>
            <td style="width:80%;" colspan="4"><span style="text-align: left;">{{printModel.formModel.descProductionProType}}</span></td>
        </tr>
        </table>
        <div style="clear: both"></div>
        <div style="height: 20px"></div> 
        <div style="font-size:18px;text-align: center;"><b>【质量控制要求】</b></div>
        <table
        style="width: 98%;text-align: center;"
        border="1px gray"
        cellspacing="0"
        class="tab"
        >
        <tr>
            <td style="width:5%;"><span>序号</span></td>
            <td style="width:19%;"><span>指标名称</span></td>
            <td style="width:19%;"><span>分子式或结构式（如能明确）</span></td>
            <td style="width:19%;"><span>CAS号（如能明确）</span></td>
            <td style="width:19%;"><span>测试方法名称</span></td>
            <td style="width:19%;"><span>定量范围</span></td>
        </tr>
        <tr v-show="printModel.formModel.targetName">
            <td><span>1</span></td>
            <td><span>{{printModel.formModel.targetName}}</span></td>
            <td><span>{{printModel.formModel.molecularOrStructureFormula}}</span></td>
            <td><span>{{printModel.formModel.casNumber}}</span></td>
            <td><span>{{printModel.formModel.testMethodName}}</span></td>
            <td><span>{{printModel.formModel.quantitativeRange}}</span></td>
        </tr>
        <tr v-for="(item, qcrIndex) in printModel.qualityControlRequirementsArr" :key="qcrIndex+5000">
            <td><span>{{qcrIndex+1+(printModel.formModel.targetName!=""?1:0)}}</span></td>
            <td><span>{{item.targetName}}</span></td>
            <td><span>{{item.molecularOrStructureFormula}}</span></td>
            <td><span>{{item.casNumber}}</span></td>
            <td><span>{{item.testMethodName}}</span></td>
            <td><span>{{item.quantitativeRange}}</span></td>
        </tr>
        </table>
        <div style="clear: both"></div>
        <div style="height: 20px"></div> 
        <div style="font-size:18px;text-align: center;"><b>【其他行业使用要求简述】</b></div>
        <div>仅供进一步加工使用。</div>
        <div style="clear: both"></div>
        <div style="height: 20px"></div> 
        <div style="font-size:18px;text-align: center;"><b>【风险物质限量要求】</b></div>
        <table
        style="width: 98%;text-align: center;"
        border="1px gray"
        cellspacing="0"
        class="tab"
        >
        <tr>
            <td style="width:5%;"><span>序号</span></td>
            <td style="width:21%;"><span>风险物质名称</span></td>
            <td style="width:21%;"><span>CAS号</span></td>
            <td style="width:21%;"><span>限量要求</span></td>
            <td style="width:32%;"><span>备注</span></td>
        </tr>
        <tr v-show="printModel.formModel.riskSubstanceName">
            <td><span>1</span></td>
            <td><span>{{printModel.formModel.riskSubstanceName}}</span></td>
            <td><span>{{printModel.formModel.casNumberSure}}</span></td>
            <td><span>{{printModel.formModel.limitRequirements}}</span></td>
            <td><span>{{printModel.formModel.remark}}</span></td>
        </tr>
        <tr v-for="(item, rslrIndex) in printModel.riskSubstanceLimitRequireArr" :key="rslrIndex+6000">
            <td><span>{{rslrIndex+1+(printModel.formModel.riskSubstanceName!=""?1:0)}}</span></td>
            <td><span>{{item.riskSubstanceName}}</span></td>
            <td><span>{{item.casNumberSure}}</span></td>
            <td><span>{{item.limitRequirements}}</span></td>
            <td><span>{{item.remark}}</span></td>
        </tr>
        </table>
        <div style="clear: both"></div>
        <div style="height: 20px"></div> 
        <div style="font-size:18px;text-align: center;"><b>【其他需要说明的问题】</b></div>
        <div>无</div>
      </div>
      
        <FooterSigner></FooterSigner>
    </div>
  </div>
</template>
<script>
import FooterSigner from "./FooterSigner";
import util from "../../common/base";
export default {
  name: "Safety",
  mixins: [util],
  props: ["printModel"],
  components: { FooterSigner },
  data() {
    return {
      loginName: localStorage.getItem("loginUserNameKey"),
    };
  },
  computed: {
    maxArr() {
      let tmp = [
        this.printModel.inclNameArr.length,
        this.printModel.commonOtherNameArr.length,
        this.printModel.percentageArr.length,
      ].sort();
      return tmp[tmp.length - 1];
    }, 
    rowspanCount(){
      return this.maxArrLength+this.customRowArr1Length+this.customRowArr2Length+this.customRowArr3Length+this.customRowKey1+this.customRowKey2+this.customRowKey3
      +this.glycerinNumber+this.propyleneGlycolNumber+this.polysorbate_80Number+this.caprylhydroxamicAcidNumber+this.oneTwoHexanediolNumber+this.oneThreePropanediolNumber
      +this.phenoxyethanolNumber+this.dmdmHydantoinNumber+this.sodiumBenzoateNumber+this.potassiumSorbateNumber+this.edtaNumber+this.waterNumber
      +1
    },
    maxArrLength(){
        return this.printModel.commonOtherNameArr.length>=this.printModel.inclNameArr.length?
                this.printModel.commonOtherNameArr.length:
                (this.printModel.commonOtherNameArr.length>=this.printModel.percentageArr.length?
                this.printModel.commonOtherNameArr.length:this.printModel.percentageArr.length)
    },
    customRowArr1Length(){
        return this.printModel.customRowArr1.length
    },
    customRowArr2Length(){
        return this.printModel.customRowArr2.length
    },
    customRowArr3Length(){
        return this.printModel.customRowArr3.length
    },
    customRowKey1(){
      return this.printModel.formModel.customRowKey1!=""?1:0
    },
    customRowKey2(){
      return this.printModel.formModel.customRowKey2!=""?1:0
    },
    customRowKey3(){
      return this.printModel.formModel.customRowKey3!=""?1:0
    },
    glycerinNumber(){
        return this.printModel.formModel.isGlycerin?1:0
    },
    propyleneGlycolNumber(){
        return this.printModel.formModel.isPropyleneGlycol?1:0
    },
    polysorbate_80Number(){
        return this.printModel.formModel.isPolysorbate_80?1:0
    },
    caprylhydroxamicAcidNumber(){
        return this.printModel.formModel.isCaprylhydroxamicAcid?1:0
    },
    oneTwoHexanediolNumber(){
        return this.printModel.formModel.isOneTwoHexanediol?1:0
    },
    oneThreePropanediolNumber(){
        return this.printModel.formModel.isOneThreePropanediol?1:0
    },
    phenoxyethanolNumber(){
        return this.printModel.formModel.isPhenoxyethanol?1:0
    },
    dmdmHydantoinNumber(){
        return this.printModel.formModel.isDmdmHydantoin?1:0
    },
    sodiumBenzoateNumber(){
        return this.printModel.formModel.isSodiumBenzoate?1:0
    },
    potassiumSorbateNumber(){
        return this.printModel.formModel.isPotassiumSorbate?1:0
    },
    edtaNumber(){
        return this.printModel.formModel.isEdta?1:0
    },
    waterNumber(){
        return this.printModel.formModel.isWater?1:0
    },
  },
  methods: {
    getTime() {
      return util.methods.getLocalTime(-5);
    },
    formatDate(time) {
      return util.methods.formatDate({ date: time });
    },
    isSingle(){
        var count = 0;
        var name = "";
        var arr = [];
        if(this.printModel.commonOtherNameArr.length>=1){
            name="Common Name";
            count = count+1;
            arr = this.printModel.commonOtherNameArr;
        }
        if(this.printModel.inclNameArr.length>=1){
            name="INCI name";
            count = count+1;
            arr = this.printModel.inclNameArr;
        }
        if(this.printModel.percentageArr.length>=1){
            name="Percentage";
            count = count+1;
            arr = this.printModel.percentageArr;
        }
        let res =  {count,name,arr};
        return res
      }
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
    if (userInfo) {
      localStorage.setItem("loginUserNameKey", userInfo.username);
    }
  },
  mounted() {},
};
</script>
