<template>
  <div
    class="app-new-item"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading..."
    :style="{ width: sWidth }"
  >
    <div id="printDiv" v-show="printShow">
      <CnSafety
        :printModel="this.$data"
        style="height-max: 1000px"
      ></CnSafety>
    </div>
    <div class="new-item-head">
      原料安全相关信息 {{ titleType }}
      <span
        style="position: absolute; left: 10px; cursor: pointer"
        @click="back"
      >
        <i class="el-icon-arrow-left"></i>
      </span>
      <span
        style="
          position: absolute;
          right: 10px;
          cursor: pointer;
          font-weight: 400;
          font-size: 14px;
        "
      >
        <el-dropdown placement>
          <div>
            <i class="el-icon-user" style="margin-right: 10px"></i>
            {{ userInfo.username }}
            <i class="el-icon-arrow-down"></i>
            <!-- <span class="user-name">语言切换</span> -->
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item @click.native="logoutHandle"
                >Logout</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </span>
    </div>
    <div class="new-item-content">
      <div class="base-info">
        <div style="margin-left: 6%"></div>
        <div>
          <div>
            <span>产品编号：</span>
            <el-autocomplete
              clearable
              ref="itemCode"
              v-model="formModel.itemCode"
              :fetch-suggestions="codeSearch"
              placeholder="请输入"
              popper-class="select-option"
              :popper-append-to-body="false"
              @select="itemCodeSearch"
            ></el-autocomplete>
          </div>
          <div>
            <span>原料商品名：</span>
            <el-input
              v-model="formModel.productName"
              placeholder="请输入"
            ></el-input>
          </div>
        </div>
      </div>
        <div class="base-info">
          <div></div>
          <div>
            <div style="height: auto">
              <span>组分中文名称：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left">
                <div style="display: flex;justify: flex-start;flex-wrap: wrap;align-items: center;">
                  <el-tag
                    :key="i+200"
                    v-for="(tag, i) in commonOtherNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(commonOtherNameArr, tag, i)"
                    @close="handleClose(tag, 'commonOtherNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="commonOtherNameInputVisible"
                    v-model="commonOtherNameInputValue"
                    ref="commonOtherNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'commonOtherNameInputValue',
                        'commonOtherNameArr',
                        'commonOtherNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'commonOtherNameInputValue',
                        'commonOtherNameArr',
                        'commonOtherNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="commonOtherNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'commonOtherNameInputVisible',
                        'commonOtherNameSaveTagInput'
                      )
                    "
                    >+ 新增组分中文名称</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>组分INCI名称：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i+300"
                    v-for="(tag, i) in inclNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(inclNameArr, tag, i)"
                    @close="handleClose(tag, 'inclNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="inclNameInputVisible"
                    v-model="inclNameInputValue"
                    ref="inclNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="inciNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('inclNameInputVisible', 'inclNameSaveTagInput')
                    "
                    >+ 新增组分INCI名称</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
            <span>百分比范围：</span>
            <div style="display: inline-block; width: 615px; text-align: left">
              <div
                style="
                  display: flex;
                  justify: flex-start;
                  flex-wrap: wrap;
                  align-items: center;
                "
              >
                <el-tag
                  :key="i+500"
                  v-for="(tag, i) in percentageArr"
                  closable
                  :disable-transitions="false"
                  @click="tagHandle(percentageArr, tag, i)"
                  @close="handleClose(tag, 'percentageArr')"
                  >{{ tag }}</el-tag
                >
                <el-input
                  class="input-new-tag"
                  v-if="percentageInputVisible"
                  v-model="percentageInputValue"
                  ref="percentageSaveTagInput"
                  size="small"
                  @keyup.enter.native="
                    handleInputConfirm(
                      'percentageInputValue',
                      'percentageArr',
                      'percentageInputVisible'
                    )
                  "
                  @blur="
                    handleInputConfirm(
                      'percentageInputValue',
                      'percentageArr',
                      'percentageInputVisible'
                    )
                  "
                ></el-input>
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="
                    showInput(
                      'percentageInputVisible',
                      'percentageSaveTagInput'
                    )
                  "
                  >+ 新增百分比范围</el-button
                >
              </div>
            </div>
            </div>
            <div>
              <span>自定义行：</span>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="formModel.customRowKey1"
                  class="custom-row"
                  placeholder="请输入名称"
                  style="margin-right: 10px;width: 180px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="formModel.customRowValue1_1"
                  placeholder="请输入值"
                  style="margin-right: 10px;width: 180px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="formModel.customRowValue1_2"
                  placeholder="请输入值"
                  style="margin-right: 10px;width: 180px;"
                ></el-input>
              </div>
            <el-button type="primary" icon="el-icon-plus" circle @click="addCustomRow1" ></el-button>
            </div>
            <template>
            <div 
            v-for="(item, index1) in customRowArr1"
            :key="index1+1000">
              <span style="margin-left: 50px;">自定义行：</span>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.customRowKey1"
                  class="custom-row"
                  placeholder="请输入名称"
                  style="margin-right: 10px;width: 180px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.customRowValue1"
                  placeholder="请输入值"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.customRowValue2"
                  placeholder="请输入值"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
            <el-button type="primary" icon="el-icon-plus" circle @click="addCustomRow1" ></el-button>
            <el-button type="danger" icon="el-icon-delete" circle @click="removeCustomRow1(index1)" ></el-button>
            </div>
            </template>
            <div style="height: auto">
              <span>甘油：</span>
              <div class="el-input" style="width: 20px;">
              <el-checkbox
                  v-model="formModel.isGlycerin"
                  placeholder="Please Input"
                ></el-checkbox>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isGlycerin"
                  v-model="formModel.glycerin1"
                  class="custom-row"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isGlycerin"
                  v-model="formModel.glycerin2"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
            </div>
            <div style="height: auto">
              <span>丙二醇：</span>
              <div class="el-input" style="width: 20px;">
                <el-checkbox
                v-model="formModel.isPropyleneGlycol"
                placeholder="Please Input"
                ></el-checkbox>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isPropyleneGlycol"
                  v-model="formModel.propyleneGlycol1"
                  class="custom-row"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isPropyleneGlycol"
                  v-model="formModel.propyleneGlycol2"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
            </div>
            <div style="height: auto">
              <span>聚山梨醇酯-80：</span>
              <div class="el-input" style="width: 20px;">
                <el-checkbox
                v-model="formModel.isPolysorbate_80"
                placeholder="Please Input"
                ></el-checkbox>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isPolysorbate_80"
                  v-model="formModel.polysorbate_80_1"
                  class="custom-row"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isPolysorbate_80"
                  v-model="formModel.polysorbate_80_2"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
            </div>
            <div style="height: auto">
              <span>辛酰羟肟酸：</span>
              <div class="el-input" style="width: 20px;">
                <el-checkbox
                v-model="formModel.isCaprylhydroxamicAcid"
                placeholder="Please Input"
                ></el-checkbox>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isCaprylhydroxamicAcid"
                  v-model="formModel.caprylhydroxamicAcid1"
                  class="custom-row"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isCaprylhydroxamicAcid"
                  v-model="formModel.caprylhydroxamicAcid2"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
            </div>
            <div style="height: auto">
              <span>1,2-己二醇：</span>
              <div class="el-input" style="width: 20px;">
                <el-checkbox
                v-model="formModel.isOneTwoHexanediol"
                placeholder="Please Input"
                ></el-checkbox>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isOneTwoHexanediol"
                  v-model="formModel.oneTwoHexanediol1"
                  class="custom-row"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isOneTwoHexanediol"
                  v-model="formModel.oneTwoHexanediol2"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
            </div>
            <div style="height: auto">
              <span>1,3-丙二醇：</span>
              <div class="el-input" style="width: 20px;">
                <el-checkbox
                v-model="formModel.isOneThreePropanediol"
                placeholder="Please Input"
                ></el-checkbox>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isOneThreePropanediol"
                  v-model="formModel.oneThreePropanediol1"
                  class="custom-row"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isOneThreePropanediol"
                  v-model="formModel.oneThreePropanediol2"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
            </div>
            <div>
              <span>自定义行：</span>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="formModel.customRowKey2"
                  class="custom-row"
                  placeholder="请输入名称"
                  style="margin-right: 10px;width: 180px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="formModel.customRowValue2_1"
                  placeholder="请输入值"
                  style="margin-right: 10px;width: 180px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="formModel.customRowValue2_2"
                  placeholder="请输入值"
                  style="margin-right: 10px;width: 180px;"
                ></el-input>
              </div>
            <el-button type="primary" icon="el-icon-plus" circle @click="addCustomRow2" ></el-button>
            </div>
            <template>
            <div 
            v-for="(item, index2) in customRowArr2"
            :key="index2+2000">
              <span style="margin-left: 50px;">自定义行：</span>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.customRowKey1"
                  class="custom-row"
                  placeholder="请输入名称"
                  style="margin-right: 10px;width: 180px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.customRowValue1"
                  placeholder="请输入值"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.customRowValue2"
                  placeholder="请输入值"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
            <el-button type="primary" icon="el-icon-plus" circle @click="addCustomRow2" ></el-button>
            <el-button type="danger" icon="el-icon-delete" circle @click="removeCustomRow2(index2)" ></el-button>
            </div>
            </template>           
            <div style="height: auto">
              <span>苯氧乙醇：</span>
              <div class="el-input" style="width: 20px;">
                <el-checkbox
                v-model="formModel.isPhenoxyethanol"
                placeholder="Please Input"
                ></el-checkbox>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isPhenoxyethanol"
                  v-model="formModel.phenoxyethanol1"
                  class="custom-row"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isPhenoxyethanol"
                  v-model="formModel.phenoxyethanol2"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
            </div>
            <div style="height: auto">
              <span>DMDM 乙内酰脲：</span>
              <div class="el-input" style="width: 20px;">
                <el-checkbox
                v-model="formModel.isDmdmHydantoin"
                placeholder="Please Input"
                ></el-checkbox>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isDmdmHydantoin"
                  v-model="formModel.dmdmHydantoin1"
                  class="custom-row"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isDmdmHydantoin"
                  v-model="formModel.dmdmHydantoin2"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
            </div>
            <div style="height: auto">
              <span>苯甲酸钠：</span>
              <div class="el-input" style="width: 20px;">
                <el-checkbox
                v-model="formModel.isSodiumBenzoate"
                placeholder="Please Input"
                ></el-checkbox>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isSodiumBenzoate"
                  v-model="formModel.sodiumBenzoate1"
                  class="custom-row"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isSodiumBenzoate"
                  v-model="formModel.sodiumBenzoate2"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
            </div>
            <div style="height: auto">
              <span>山梨酸钾：</span>
              <div class="el-input" style="width: 20px;">
                <el-checkbox
                v-model="formModel.isPotassiumSorbate"
                placeholder="Please Input"
                ></el-checkbox>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isPotassiumSorbate"
                  v-model="formModel.potassiumSorbate1"
                  class="custom-row"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isPotassiumSorbate"
                  v-model="formModel.potassiumSorbate2"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
            </div>
            <div style="height: auto">
              <span>乙二胺四乙酸：</span>
              <div class="el-input" style="width: 20px;">
                <el-checkbox
                v-model="formModel.isEdta"
                placeholder="Please Input"
                ></el-checkbox>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isEdta"
                  v-model="formModel.edta1"
                  class="custom-row"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isEdta"
                  v-model="formModel.edta2"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
            </div>
            <div style="height: auto">
              <span>水：</span>
              <div class="el-input" style="width: 20px;">
                <el-checkbox
                v-model="formModel.isWater"
                placeholder="Please Input"
                ></el-checkbox>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isWater"
                  v-model="formModel.water1"
                  class="custom-row"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 290px;">
                <el-input
                  v-show="formModel.isWater"
                  v-model="formModel.water2"
                  placeholder="请输入"
                  style="margin-right: 10px;width: 285px;"
                ></el-input>
              </div>
            </div>
            <div>
              <span>自定义行：</span>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="formModel.customRowKey3"
                  class="custom-row"
                  placeholder="请输入名称"
                  style="margin-right: 10px;width: 180px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="formModel.customRowValue3_1"
                  placeholder="请输入值"
                  style="margin-right: 10px;width: 180px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="formModel.customRowValue3_2"
                  placeholder="请输入值"
                  style="margin-right: 10px;width: 180px;"
                ></el-input>
              </div>
            <el-button type="primary" icon="el-icon-plus" circle @click="addCustomRow3" ></el-button>
            </div>
            <template>
            <div 
            v-for="(item, index3) in customRowArr3"
            :key="index3+3000">
              <span style="margin-left: 50px;">自定义行：</span>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.customRowKey1"
                  class="custom-row"
                  placeholder="请输入名称"
                  style="margin-right: 10px;width: 180px;"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.customRowValue1"
                  placeholder="请输入值"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.customRowValue2"
                  placeholder="请输入值"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
            <el-button type="primary" icon="el-icon-plus" circle @click="addCustomRow3" ></el-button>
            <el-button type="danger" icon="el-icon-delete" circle @click="removeCustomRow3(index3)" ></el-button>
            </div>
            </template>
            <div>
                <span>化妆品中建议添加量：</span>
                <el-input
                v-model="formModel.recommendedAmountCosmetics"
                placeholder="请输入"
                ></el-input>
            </div>
            <div>
                <span>原料使用限制（如有）：</span>
                <el-input
                v-model="formModel.restrictionsUseRawMaterials"
                placeholder="请输入"
                ></el-input>
            </div>
            <div>
                <span>原料性状：</span>
                <el-autocomplete
                clearable
                ref="propertiesRawMaterials"
                v-model="formModel.propertiesRawMaterials"
                :fetch-suggestions="propertiesRawMaterialsSearch"
                placeholder="请输入"
                popper-class="select-option"
                :popper-append-to-body="false"
                ></el-autocomplete>
            </div>
            <div>
                <span>物理化学性质描述：</span>
                <el-autocomplete
                clearable
                ref="descPhysChemProperties"
                v-model="formModel.descPhysChemProperties"
                :fetch-suggestions="descPhysChemPropertiesSearch"
                placeholder="请输入"
                popper-class="select-option"
                :popper-append-to-body="false"
                ></el-autocomplete>
            </div>
            <div>
                <span>生产工艺类型描述：</span>
                <el-autocomplete
                clearable
                ref="descProductionProType"
                v-model="formModel.descProductionProType"
                :fetch-suggestions="descProductionProTypeSearch"
                placeholder="请输入"
                popper-class="select-option"
                :popper-append-to-body="false"
                ></el-autocomplete>
            </div>
          </div>
        </div>
        <!-- 质量控制要求 -->
        <div class="base-info">
          <div>质量控制要求</div>
          <div>
            <table
              class="AnalysisTable"
              border="1"
              cellspacing="0"
              cellpadding="0"
            >
              <tr>
                <td style="width: 20%" class="FontWeight">指标名称</td>
                <td style="width: 20%" class="FontWeight">分子式或结构式（如能明确）</td>
                <td style="width: 20%" class="FontWeight">CAS号（如能明确）</td>
                <td style="width: 20%" class="FontWeight">测试方法名称</td>
                <td style="width: 20%" class="FontWeight">定量范围</td>
              </tr>              
              <tr>
                <td>                    
                  <el-input
                    v-model="formModel.targetName"
                    placeholder="请输入指标名称"
                    class="InputWidth"
                    @blur="getTargetNameInfo(formModel.targetName,-1)"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="formModel.molecularOrStructureFormula"
                    placeholder="请输入分子式或结构式"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="formModel.casNumber"
                    placeholder="请输入CAS号"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="formModel.testMethodName"
                    placeholder="请输入测试方法名称"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="formModel.quantitativeRange"
                    placeholder="请输入定量范围"
                    class="InputWidth"
                  ></el-input>
                </td>
                <el-button
                  type="primary"
                  icon="el-icon-plus" circle
                  @click="addQualityControlRequirements"
                  ></el-button
                >
              </tr>              
              <tr
                v-for="(item, qcrIndex) in qualityControlRequirementsArr"
                :key="qcrIndex+4000"
              >
                <td>
                  <el-input
                    v-model="item.targetName"
                    class="InputWidth"
                    placeholder="请输入指标名称"
                    @blur="getTargetNameInfo(item.targetName,qcrIndex)"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.molecularOrStructureFormula"
                    class="InputWidth"
                    placeholder="请输入分子式或结构式"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.casNumber"
                    class="InputWidth"
                    placeholder="请输入CAS号"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.testMethodName"
                    class="InputWidth"
                    placeholder="请输入测试方法名称"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.quantitativeRange"
                    class="InputWidth"
                    placeholder="请输入定量范围"
                  ></el-input>
                </td>
                <el-button type="primary" icon="el-icon-plus" circle @click="addQualityControlRequirements" ></el-button>
                <el-button type="danger" icon="el-icon-delete" style="margin-left: 0px;" circle @click="removeQualityControlRequirements(qcrIndex)"></el-button>
              </tr>
            </table>
          </div>
        </div>
        <!-- 风险物质限量要求 -->
        <div class="base-info">
          <div>风险物质限量要求</div>
          <div>
            <table
              class="AnalysisTable"
              border="1"
              cellspacing="0"
              cellpadding="0"
            >
              <tr>
                <td style="width: 25%" class="FontWeight">风险物质名称</td>
                <td style="width: 25%" class="FontWeight">CAS号</td>
                <td style="width: 25%" class="FontWeight">限量要求</td>
                <td style="width: 25%" class="FontWeight">定量范围</td>
              </tr>
              <tr>
                <td>                    
                  <el-input
                    v-model="formModel.riskSubstanceName"
                    placeholder="请输入风险物质名称"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="formModel.casNumberSure"
                    placeholder="请输入CAS号"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="formModel.limitRequirements"
                    placeholder="请输入限量要求"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="formModel.remark"
                    placeholder="请输入备注"
                    class="InputWidth"
                  ></el-input>
                </td>
                <el-button
                  type="primary"
                  icon="el-icon-plus" circle
                  @click="addRiskSubstanceLimitRequire"
                  ></el-button
                >
              </tr>
              
              <tr
                v-for="(item, rslrIndex) in riskSubstanceLimitRequireArr"
                :key="rslrIndex+5000"
              >
                <td>
                  <el-input
                    v-model="item.riskSubstanceName"
                    class="InputWidth"
                    placeholder="请输入风险物质名称"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.casNumberSure"
                    class="InputWidth"
                    placeholder="请输入CAS号"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.limitRequirements"
                    class="InputWidth"
                    placeholder="请输入限量要求"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.remark"
                    class="InputWidth"
                    placeholder="请输入备注"
                  ></el-input>
                </td>
                <el-button type="primary" icon="el-icon-plus" circle @click="addRiskSubstanceLimitRequire" ></el-button>
                <el-button type="danger" icon="el-icon-delete" style="margin-left: 0px;" circle @click="removeRiskSubstanceLimitRequire(rslrIndex)"></el-button>
              </tr>
            </table>
          </div>
        </div>
        <!-- Save -->
        <div class="save-bottom">
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('ContinueEdit')">保存</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('Save&New')">保存并新增</el-button>
          <el-button @click="back">返回首页</el-button>
          <el-button type="success" icon="el-icon-printer" @click="printPage()">打印</el-button>
        </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import printJS from "print-js";
import CnSafety from "../PrintProductTypeModule/Cn-Safety.vue";
export default {
  name: "composition",
  mixins: [base],
  components: { CnSafety },
  data() {
    return {
      titleType: "添加",
      sWidth: 0,
      loading: false,
      itemCodeSearchLock: "", 
      //#region 空白行
      customRowArr1:[],
      customRowArr2:[],
      customRowArr3:[],
      customRow:{
        CustomRowKey1:"",
        CustomRowValue1:"",
        CustomRowValue2:"",
      },
      //#endregion
      //#region 数组
      inclNameArr: [],
      inclNameInputVisible: "",
      inclNameInputValue: "",
      inciNameD: false,

      commonOtherNameArr: [],
      commonOtherNameInputVisible: "",
      commonOtherNameInputValue: "",
      commonOtherNameD: false,

      percentageArr: [],
      percentageInputVisible: "",
      percentageInputValue: "",
      percentageD: false,
      //#endregion
      formModel: {
        itemCode: "",
        productName: "",
        commonOtherName: "",
        iNCIName: "",
        percentage: "",

        isGlycerin:false,
        glycerin1:"GLYCERIN",//甘油
        glycerin2:"",
        isPropyleneGlycol:false,
        propyleneGlycol1:"PROPYLENE GLYCOL",//丙二醇
        propyleneGlycol2:"",
        isPolysorbate_80:false,
        polysorbate_80_1:"POLYSORBATE 80",//聚山梨醇酯-80
        polysorbate_80_2:"",
        isCaprylhydroxamicAcid:false,
        caprylhydroxamicAcid1:"CAPRYLHYDROXAMIC ACID",//辛酰羟肟酸
        caprylhydroxamicAcid2:"",
        isOneTwoHexanediol:false,
        oneTwoHexanediol1:"1,2-HEXANEDIOL",//1,2-己二醇
        oneTwoHexanediol2:"",
        isOneThreePropanediol:false,
        oneThreePropanediol1:"PROPANEDIOL",//1,3-丙二醇
        oneThreePropanediol2:"",
        isPhenoxyethanol:false,
        phenoxyethanol1:"PHENOXYETHANOL",//苯氧乙醇
        phenoxyethanol2:"",
        isDmdmHydantoin:false,
        dmdmHydantoin1:"DMDM HYDANTOIN",//DMDM 乙内酰脲
        dmdmHydantoin2:"",
        isSodiumBenzoate:false,
        sodiumBenzoate1:"Sodium Benzoate",//苯甲酸钠
        sodiumBenzoate2:"",
        isPotassiumSorbate:false,
        potassiumSorbate1:"POTASSIUM SORBATE",//山梨酸钾
        potassiumSorbate2:"",
        isEdta:false,
        edta1:"EDTA",//乙二胺四乙酸
        edta2:"",
        isWater:false,
        water1:"WATER",//水
        water2:"",
        
        customRow1:"",
        customRow2:"",
        customRow3:"",
        customRowKey1:"",
        customRowValue1_1:"",
        customRowValue1_2:"",
        customRowKey2:"",
        customRowValue2_1:"",
        customRowValue2_2:"",
        customRowKey3:"",
        customRowValue3_1:"",
        customRowValue3_2:"",

        recommendedAmountCosmetics:"",//化妆品中建议添加量
        restrictionsUseRawMaterials:"没有",//原料使用限制（如有）
        propertiesRawMaterials:"",//原料性状
        descPhysChemProperties:"",//物理化学性质描述
        descProductionProType:"",//生产工艺类型描述

        targetName:"",//指标名称
        molecularOrStructureFormula :"",//分子式或结构式（如能明确）
        casNumber:"",//CAS号（如能明确）
        testMethodName:"",//测试方法名称
        quantitativeRange:"",//定量范围
        qualityControlRequirements:"",//质量控制要求集合

        riskSubstanceName:"",//风险物质名称
        casNumberSure:"",//CAS号
        limitRequirements:"",//限量要求
        remark:"",//备注
        riskSubstanceLimitRequire:"",//风险物质限量要求集合

      },
      propertiesRawMaterialsList:["淡黄棕色液体","淡红棕色液体","淡黄至淡棕色油状液体","白色至淡灰色粉状提取物"],//原料性状
      descPhysChemPropertiesList:["液体，不易燃","粉状提取物，惰性","油状物"],//物理化学性质描述
      descProductionProTypeList:[
          "物理粉碎 – 纯水+多元醇提取 – 真空浓缩 – 添加防腐剂，混合均匀",
          "物理粉碎 – 发酵 – 真空浓缩 – 添加防腐剂，混合均匀",
          "物理粉碎 – 纯水提取 – 真空浓缩 – 喷雾干燥 – 真空包装",
          "物理粉碎 – 超临界提取 – 分离纯化 – 添加溶剂，混合均匀",
          "物理粉碎 – 超临界二氧化碳提取 – 分离纯化 – 纯水提取 – 真空浓缩 – 合并 – 添加溶剂和防腐剂，混合均匀",
          ],//物理化学性质描述
      qualityControlRequirementsArr:[],//质量控制要求 QualityControlRequirements
      qualityControlRequirements:{
        targetName:"",//指标名称
        molecularOrStructureFormula :"",//分子式或结构式（如能明确）
        casNumber:"",//CAS号（如能明确）
        testMethodName:"",//测试方法名称
        quantitativeRange:"",//定量范围
      },
      riskSubstanceLimitRequireArr:[],//风险物质限量要求
      riskSubstanceLimitRequire:{
        riskSubstanceName:"",//风险物质名称
        casNumberSure:"",//CAS号
        limitRequirements:"",//限量要求
        remark:"",//备注 
      },
      formData: {
        id: 0,
        productName: "",
        itemCode: "",
        jsonValue: {},
      },
      printShow: false,
      printObj: {
        id: "printDiv", // 这里是要打印元素的ID
        popTitle: "", // 打印的标题
        extraCss: "", // 打印可引入外部的一个 css 文件
        extraHead: "", // 打印头部文字
      },
      createTime: "",
    };
  },
  computed: {},
  methods: {
    back() {
      this.$router.push({ path: "/cn-home" });
    },
    codeSearch(queryString, cb) {
      this.getDetailByItemCodeSearch(queryString);
      var codeList = JSON.parse(localStorage.getItem("itemCodeListKey"));
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.itemCode });
      });
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    propertiesRawMaterialsSearch(queryString, cb) {
      this.getDetailByItemCodeSearch(queryString);
      var codeList = this.propertiesRawMaterialsList;
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element });
      });
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    descPhysChemPropertiesSearch(queryString, cb) {
      this.getDetailByItemCodeSearch(queryString);
      var codeList = this.descPhysChemPropertiesList;
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element });
      });
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    descProductionProTypeSearch(queryString, cb) {
      this.getDetailByItemCodeSearch(queryString);
      var codeList = this.descProductionProTypeList;
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element });
      });
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    itemCodeSearch(val) {
      if (typeof val.value != "undefined") {
        console.info(val.value);
        this.getDetailByItemCodeSearch(val.value);
      }
    },
    showInput: function (inputVisible, ref) {
      console.log(inputVisible);
      this[inputVisible] = true;
      this.$nextTick(() => {
        this.$refs[ref].$refs.input.focus();
      });
    },
    handleInputConfirm(value, arr, visible) {
      let inputValue = this[value];
      if (inputValue) {
        console.info(this[arr]);
        this[arr].push(inputValue);
      }

      this[visible] = false;
      this[value] = "";
    },
    strSplit(context, type) {
      this[type] = []; //清理旧有数据防止重复
      if (!context) {
        return;
      }
      let splitArr = context.split("_");
      if (splitArr.length == 1) {
        splitArr = context.split(",");
      }
      splitArr.forEach((v) => {
        if (v.trim().length != 0) {
          this[type].push(v);
        }
      });
    },
    getDetailByItemCodeSearch(searchValue) {
      if (this.itemCodeSearchLock == searchValue) {
        this.itemCodeSearchLock = searchValue;
        return;
      }
      if (typeof searchValue == "undefined" || searchValue.length == 0) {
        return;
      }

      this.$api.handleSpec
        .getDetailByItemCode(searchValue)
        .then((res) => {
          if (res != null) {
            console.log("res")
            console.log(res)
            this.formModel.productName = res.productName;
            this.strSplit(res.inciName, "inclNameArr");
            this.strSplit(res.commonOtherName, "commonOtherNameArr");
            this.strSplit(res.percentage, "percentageArr");
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    printPage() {
      this.printShow = true;
      const style = "@page {margin:0} @media print{}";
      var printFileName = ""; 
      if(this.formModel.productName!=""&&this.formModel.productName!=undefined){
        printFileName = this.formModel.productName.replace(":","~").replace("：", "~");
      }
      document.title = printFileName;
      setTimeout(() => {
        printJS({
          printable: "printDiv",
          type: "html",
          style: style,
          scanStyles: false,
        });
      }, 1.5);
      setTimeout(() => {
        this.printShow = false;
      }, 3);
    },
    tagHandle(array, tagCotext, index) {
      //tag标签点击事件
      console.info(array, tagCotext, index);
      this.$prompt("修改值-->" + tagCotext, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          array.splice(index, 1, value);
        })
        .catch(() => {});
    }, // ingredients标签操作
    handleClose(tag, arr) {
      this[arr].splice(this[arr].indexOf(tag), 1);
    },
    handleNew(type) {
      let params = { ...this.$route.query };
      console.log("=============");
      this.formModel.iNCIName = this.inclNameArr.join("_");
      this.formModel.commonOtherName = this.commonOtherNameArr.join("_");
      this.formModel.percentage = this.percentageArr.join("_");
      
      this.formModel.customRow1 = JSON.stringify(this.customRowArr1)
      this.formModel.customRow2 = JSON.stringify(this.customRowArr2)
      this.formModel.customRow3 = JSON.stringify(this.customRowArr3)
      this.formModel.qualityControlRequirements = JSON.stringify(this.qualityControlRequirementsArr)
      this.formModel.riskSubstanceLimitRequire = JSON.stringify(this.riskSubstanceLimitRequireArr)

      let jsonStr = JSON.stringify(this.formModel);
      console.log(jsonStr);
      this.formData.productName = this.formModel.productName;
      this.formData.itemCode = this.formModel.itemCode;
      this.formData.jsonValue = jsonStr;
      console.log(this.formData);
      console.log(this.formModel);
      if (params.id) {
        this.formData.id = params.id;
        this.$api.handleCnRawmaterial
          .updateCnRawmaterial(this.formData)
          .then(() => {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            // this.$router.push({
            //   path: "cn-home",
            // });
            this.reloadPage(type);
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
            console.log(err);
          });
      } else {
        this.$api.handleCnRawmaterial
          .addCnRawmaterial(this.formData)
          .then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "保存成功",
            });
            // this.$router.push({
            //   path: "cn-home",
            // });
            this.reloadPage(type);
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
            console.log(err);
          });
      }
    },
    reloadPage(type){
      if (type == "Save&New") {
        //清空数据
        this.$router.push({
          path: "cn-safety",
          query: { id: undefined },
        });
        this.$router.go(0);
      }
    },
    getDetailById(params) {
      this.loading = true;
      this.$api.handleCnRawmaterial
      .getCnRawmaterialInfo(params)
      .then((data) => {
        console.log("data");
        console.log(data);
        this.formData = data;
        this.createTime = this.formatTime(data.createTime, "yyyy-MM-dd");
        this.formModel = JSON.parse(data.jsonValue);
        this.loading = false;

        this.strSplit(this.formModel.iNCIName, "inclNameArr");
        this.strSplit(this.formModel.commonOtherName, "commonOtherNameArr");
        this.strSplit(this.formModel.percentage, "percentageArr");
        this.customRowArr1 = JSON.parse(this.formModel.customRow1)
        this.customRowArr2 = JSON.parse(this.formModel.customRow2)
        this.customRowArr3 = JSON.parse(this.formModel.customRow3)
        this.qualityControlRequirementsArr = JSON.parse(this.formModel.qualityControlRequirements)
        this.riskSubstanceLimitRequireArr = JSON.parse(this.formModel.riskSubstanceLimitRequire)
      });
    },
    addCustomRow1(){
      this.customRowArr1.push({ ...this.customRow });
    },
    removeCustomRow1(index){
      this.$delete(this.customRowArr1,index)
    },
    addCustomRow2(){
      this.customRowArr2.push({ ...this.customRow });
    },
    removeCustomRow2(index){
      this.$delete(this.customRowArr2,index)
    },
    addCustomRow3(){
      this.customRowArr3.push({ ...this.customRow });
    },
    removeCustomRow3(index){
      this.$delete(this.customRowArr3,index)
    },
    addQualityControlRequirements(){
      this.qualityControlRequirementsArr.push({ ...this.qualityControlRequirements });
    },
    removeQualityControlRequirements(index){
      this.$delete(this.qualityControlRequirementsArr,index)
    },
    addRiskSubstanceLimitRequire(){
      this.riskSubstanceLimitRequireArr.push({ ...this.riskSubstanceLimitRequire });
    },
    removeRiskSubstanceLimitRequire(index){
      this.$delete(this.riskSubstanceLimitRequireArr,index)
    },
     getTargetNameInfo(targetName,index){
       console.log("targetName")
       console.log(targetName)
       this.$api.handleCnRawmaterial
      .getCnRawmaterialTargetNameInfo(targetName)
      .then((data) => {
        console.log("data");
        console.log(data);
        if(data!=null){
          if(index==-1){
            this.formModel.molecularOrStructureFormula=data.molecularOrStructureFormula;//分子式或结构式（如能明确）
            this.formModel.casNumber=data.casNumber;//CAS号（如能明确）
            this.formModel.testMethodName=data.testMethodName;//测试方法名称
            this.formModel.quantitativeRange=data.quantitativeRange;//定量范围
          }
          else{
            this.qualityControlRequirementsArr[index].molecularOrStructureFormula=data.molecularOrStructureFormula;//分子式或结构式（如能明确）
            this.qualityControlRequirementsArr[index].casNumber=data.casNumber;//CAS号（如能明确）
            this.qualityControlRequirementsArr[index].testMethodName=data.testMethodName;//测试方法名称
            this.qualityControlRequirementsArr[index].quantitativeRange=data.quantitativeRange;//定量范围
          }
        }
      });
     },
  },
  mounted() {
    this.titleType = "添加";
    this.createTime = new Date(); 
    let params = { ...this.$route.query };
    if (params.id) {
      this.titleType = "修改";
      this.getDetailById(params.id);
      this.newItemType = "Edit";
    } else {
      this.newItemType = "New";
    }
    this.sWidth = "1920px";
  },
  created() {},
 
};
</script>
<style lang="scss" scoped>
@import "../module/NewItemStyle.scss";
</style>
<style>
.AnalysisTable {
    width: 80%;
    border: 1px solid #dadada;
    margin-left: 10%;
}

.AnalysisTable tr {
    line-height: 30px;
}

.AnalysisTable tr td {
    border: 1px solid #dadada;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.InputWidth {
    width: 100% !important;
}
</style>

