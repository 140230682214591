<template>
  <div v-if="isSignature">
    <p></p>
    <p></p>
    <div>
      <div style="clear: both; height: 50px">Please verify:</div>
      <div style="clear: both; height: 50px">
        <div style="float: left; height: 20px; width: 100px; text-align: right">
          Company
        </div>
        <div
          style="
            border-bottom: 1px solid black;
            width: 200px;
            float: left;
            height: 20px;
          "
        ></div>
      </div>
      <div style="clear: both; height: 50px">
        <div style="float: left; width: 350px">
          <div
            style="float: left; height: 20px; width: 100px; text-align: right"
          >
            Name
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              width: 200px;
              float: left;
              height: 20px;
            "
          ></div>
        </div>
        <div style="float: left">
          <div
            style="float: left; height: 20px; width: 100px; text-align: right"
          >
            Title
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              width: 200px;
              float: left;
              height: 20px;
            "
          ></div>
        </div>
      </div>
      <div style="clear: both; height: 50px">
        <div style="float: left; width: 350px">
          <div
            style="float: left; height: 20px; width: 100px; text-align: right"
          >
            Signature
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              width: 200px;
              float: left;
              height: 20px;
            "
          ></div>
        </div>
        <div style="float: left">
          <div
            style="float: left; height: 20px; text-align: right; width: 100px"
          >
            Date
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              width: 200px;
              float: left;
              height: 20px;
            "
          ></div>
        </div>
      </div>
    </div>
    <!-- <div class="note">
        <table>
            <tr v-for="(item,value,idx) in noteTableData" :key="idx">
                <td>RevisionDate:<b>{{item.revisionDate}}</b></td>
                <td>RequestedBy：<b>{{item.requestedBy}}</b></td>
                <td>ItemsRevised:<b>{{item.itemsRevised}}</b></td>
                <td>EditedBy:<b>{{item.editedBy}}</b></td>
                <td>ApprovedBy:<b>{{item.approvedBy}}</b></td>
            </tr>
        </table>
    </div> -->
  </div>
</template>
<script>
export default {
    data(){
        return {
            noteTableData:[],
            isSignature:false
        }
    },
    mounted(){
        //  this.noteTableData =  JSON.parse(localStorage.getItem('local_noteTableData'));
         this.$EventBus.$on("signatureChange", (val) => {
          this.isSignature = val;
        });
    },
}
</script>